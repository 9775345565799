import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import statusImage from "../assets/images/status/error.svg"
import Footer from "../components/App/Footer"
const Contact = () => {
  return (
    <Layout>
      <Navbar />

      <section className="pt-70 pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4 mx-md-auto text-center">
              <h1 className="display-1 center-xy text-black-50 bold">500</h1>
              <img
                src={statusImage}
                class="img-responsive w-70 mx-auto"
                alt="404"
              />
              <h1 className="bold text-danger mt-5">
                Ouch.. Something went out of our hands
              </h1>
              <p className="text-secondary">
                We are working hard to get this issue fixed.
                <br />
                You may want refresh the page or try again later.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export default Contact
